<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M7.20119 0.97929C7.39006 0.779728 7.65272 0.666672 7.92749 0.666672H12.2565C12.5313 0.666672 12.7939 0.779728 12.9828 0.97929L19.4763 7.8404C19.8412 8.226 19.8412 8.82957 19.4763 9.21517L16.2295 12.6457C16.0407 12.8453 15.778 12.9583 15.5032 12.9583C15.2285 12.9583 14.9658 12.8453 14.7769 12.6457L12.9732 10.7399L7.02816 17.3678C5.72409 18.8216 3.4647 18.884 2.08205 17.5048C0.697795 16.124 0.760787 13.8659 2.21942 12.564L8.98493 6.52581L6.11894 3.49757C5.754 3.11197 5.754 2.50841 6.11894 2.12281L7.20119 0.97929ZM10.3603 7.97902L3.55116 14.0562C2.9504 14.5923 2.92473 15.5205 3.49449 16.0888C4.06585 16.6588 5.0008 16.6327 5.53934 16.0323L11.5939 9.28243L10.3603 7.97902ZM8.22208 2.81019L8.35791 2.66667H11.8261L17.3731 8.52778L15.5032 10.5035L8.22208 2.81019Z" :fill="color"/>
	</svg>
</template>

<script>
  	export default {
    	name: 'IconWork',
    	props: ['color'],
    }
</script>
