<template>
    <popup-modal ref="popup" :size="'xmd'" @close="close">
        <div class="popup">
            <div class="popup__header">{{ title }}</div>
            <div class="component-filter">
                <div class="range-picker">
                    <DatePicker v-model="filter.begin" label="Дата от" :isClear="false" size="sm" />
                    <DatePicker v-model="filter.end" label="Дата до" :isClear="false" size="sm" />
                </div>
                <div class="component-filter__item" style="min-width: 150px; max-width: 150px;">
                    <SelectPicker :list="groupings" label="Группировка" v-model="grouping" :size="'sm'" :isClear="false" />
                </div>
                <div class="component-filter__item" style="width: 350px; max-width: 300px;">
                    <SelectPicker :list="modules" label="Модуль" v-model="filter.module" :size="'sm'" :isClear="true" />
                </div>
            </div>
            
            <div class="btn-group">
                <a class="btn btn--medium btn--default btn--inline" @click="saveFilter($event)">
                    <div class="btn--text"> Применить фильтры </div>
                    <div class="btn--loader"> </div>
                </a>
                <a class="btn btn--medium btn--outline btn--inline" @click="clearFilter($event)">
                    <div class="btn--text"> Сбросить фильтры </div>
                    <div class="btn--loader"> </div>
                </a>
            </div>

            <div class="popup__body">
                <LineChart :chartData="currDataset" :options="options" cssClasses="chart"/>
            </div>
            <div class="popup__footer">
                <div class="c-legend">
                    <div class="c-legend__item" @click="visibleState.done = !visibleState.done" :class="{'c-legend__item--disabled' : !visibleState.done}">
                        <div class="c-legend__badge" :style="{backgroundColor: '#7ecd77'}"></div> 
                        Сделано — всё хорошо
                    </div>
                    <div class="c-legend__item" @click="visibleState.issue = !visibleState.issue" :class="{'c-legend__item--disabled' : !visibleState.issue}">
                        <div class="c-legend__badge" :style="{backgroundColor: '#ff9100'}"></div> 
                        Сделано — есть проблема
                    </div>
                    <div class="c-legend__item" @click="visibleState.failed = !visibleState.failed" :class="{'c-legend__item--disabled' : !visibleState.failed}">
                        <div class="c-legend__badge" :style="{backgroundColor: '#e03712'}"></div> 
                        Не сделано
                    </div>
                    <div class="c-legend__item" @click="visibleState.expired = !visibleState.expired" :class="{'c-legend__item--disabled' : !visibleState.expired}">
                        <div class="c-legend__badge" :style="{backgroundColor: '#750000'}"></div> 
                        Просрочено
                    </div>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>

    import PopupModal from './PopupModal.vue';
    import { LineChart } from 'vue-chart-3';
    import { Chart, registerables} from 'chart.js';
    import SelectPicker from '@/components/ux/SelectPicker.vue';
    import DatePicker from '@/components/ux/DatePicker.vue';

    Chart.register(...registerables);

    export default {
        name: 'DynamicsDialogue',
        
        components: { PopupModal, LineChart, SelectPicker, DatePicker },

        data: () => ({

            modules: [
                {id: 'A', name: '<span class="module-id">A</span>Лифтовое хозяйство'},
                {id: 'B', name: '<span class="module-id">B</span>Санитарное содержание'},
                {id: 'C', name: '<span class="module-id">C</span>Конструктивные элементы'},
                {id: 'D', name: '<span class="module-id">D</span>Оборудование и системы инженерно-технического обеспечения'},
                {id: 'E', name: '<span class="module-id">E</span>Благоустройство и озеленение придомовой территории'},
                {id: 'F', name: '<span class="module-id">F</span>Обеспечение пожарной безопасности'},
                {id: 'G', name: '<span class="module-id">G</span>Качество специальных сервисов: безопасность и комфорт жизни'},
                {id: 'H', name: '<span class="module-id">H</span>Сезонные осмотры'},
                {id: 'I', name: '<span class="module-id">I</span>Регулярная уборка МОПов'},
            ],

            groupings: [
                {id: 1, name: 'День', isDisabled: false},
                {id: 2, name: 'Неделя', isDisabled: true},
                {id: 3, name: 'Месяц', isDisabled: false},
            ],



            type: null,
            
            grouping: null,

            data: null,



            filter: {
                module: null,
                begin: null,
                end: null,
            },

            displayFilter : {
                module: null,
                begin: null,
                end: null,
            },

            visibleState: {
                done: true,
                issue: true,
                failed: true,
                expired: true,
            },

            options: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                interaction: {
                  mode: 'nearest',
                  axis: 'x',
                  intersect: false
                },
                scales: {
                    x: {
                        // offset: true,
                        ticks: {
                            font: {
                                size: 10,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    },
                    y: {
                        // offset: true,
                        ticks: {
                            font: {
                                size: 10,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    }
                }
            },

        }),
        computed: {
            title () {
                switch (this.type) {
                    case 'review':
                        return 'Динамика по осмотрам';
                    case 'work':
                        return 'Динамика по работам';
                    case 'control':
                        return 'Динамика по контролю';
                    default:
                        return 'Динамика по осмотрам';
                }
            },

            house_id () {
                return this.$route.params.house_id;
            },

            currDataset () {
                
                const chartData = {
                    labels: [],
                    datasets: [{
                        label: 'Сделано — всё хорошо',
                        data: [],
                        fill: false,
                        borderColor: '#7ecd7780',
                        pointStyle: 'circle',
                        pointRadius: 2,
                        pointHoverRadius: 4,
                        pointBackgroundColor: '#7ecd77',
                        borderWidth: 1.5,
                        tension: 0.4,
                        isVisible: true,
                    },{
                        label: 'Сделано — есть проблема',
                        data: [],
                        fill: false,
                        borderColor: '#ff910080',
                        pointStyle: 'circle',
                        pointRadius: 2,
                        pointHoverRadius: 4,
                        pointBackgroundColor: '#ff9100',
                        borderWidth: 1.5,
                        tension: 0.4,
                        isVisible: true,
                    },{
                        label: 'Не сделано',
                        data: [],
                        fill: false,
                        borderColor: '#e0371280',
                        pointStyle: 'circle',
                        pointRadius: 2,
                        pointHoverRadius: 4,
                        pointBackgroundColor: '#e03712',
                        borderWidth: 1.5,
                        tension: 0.4,
                        isVisible: true,
                    },{
                        label: 'Просрочено',
                        data: [],
                        fill: false,
                        borderColor: '#75000080',
                        pointStyle: 'circle',
                        pointRadius: 2,
                        pointHoverRadius: 4,
                        pointBackgroundColor: '#750000',
                        borderWidth: 1.5,
                        tension: 0.4,
                        isVisible: true,
                    }],
                }

                if(this.data == null) return chartData;


                // Если установлена группировка "День"

                if(this.grouping == 1) {

                    this.data.forEach((item) => {
                        
                        chartData.labels.push(this.$moment(item.date, 'DD.MM.YYYY').format('DD.MM'));

                        chartData.datasets[0].data.push(item.values[0]);
                        chartData.datasets[1].data.push(item.values[1]);
                        chartData.datasets[2].data.push(item.values[2]);
                        chartData.datasets[3].data.push(item.values[3]);

                    });

                }

                // Если установлена группировка "Неделя"

                if(this.grouping == 2) {

                    const groups = [];

                    this.data.forEach((item) => {
                        let groupId = this.$moment(item.date, 'DD.MM.YYYY').week();
                        let idx = groups.findIndex((element) => { return element.groupId == groupId});
                        if(idx !== -1) {
                            groups[idx].items.push(item);
                        } else {
                            groups.push({groupId: groupId, items: [item]});
                        }
                    });

                    groups.forEach((item) => {

                        let first = this.$moment(item.items[0].date, 'DD.MM.YYYY').format('DD.MM');
                        let last = this.$moment(item.items[item.items.length - 1].date, 'DD.MM.YYYY').format('DD.MM.YY');

                        chartData.labels.push(first + ' - ' + last);

                        let dataset = [0, 0, 0, 0];

                        item.items.forEach((element) => {
                            dataset[0] += element.values[0];
                            dataset[1] += element.values[1];
                            dataset[2] += element.values[2];
                            dataset[3] += element.values[3];
                        });

                        chartData.datasets[0].data.push(dataset[0]);
                        chartData.datasets[1].data.push(dataset[1]);
                        chartData.datasets[2].data.push(dataset[2]);
                        chartData.datasets[3].data.push(dataset[3]);

                    });

                }


                // Если установлена группировка "Месяц"

                if(this.grouping == 3) {

                    const groups = [];

                    this.data.forEach((item) => {
                        let groupId = this.$moment(item.date, 'DD.MM.YYYY').format('M');
                        let groupName = this.$moment(item.date, 'DD.MM.YYYY').format('MMMM');
                        let idx = groups.findIndex((element) => { return element.groupId == groupId});
                        if(idx !== -1) {
                            groups[idx].items.push(item);
                        } else {
                            groups.push({groupId: groupId, groupName:groupName, items: [item]});
                        }
                    });

                    groups.forEach((item) => {

                        chartData.labels.push(item.groupName);

                        let dataset = [0, 0, 0, 0];

                        item.items.forEach((element) => {
                            dataset[0] += element.values[0];
                            dataset[1] += element.values[1];
                            dataset[2] += element.values[2];
                            dataset[3] += element.values[3];
                        });

                        chartData.datasets[0].data.push(dataset[0]);
                        chartData.datasets[1].data.push(dataset[1]);
                        chartData.datasets[2].data.push(dataset[2]);
                        chartData.datasets[3].data.push(dataset[3]);

                    });

                }

                // Скрываем не нужные

                if(!this.visibleState.done) {
                    chartData.datasets[0].data = [];
                }
                if(!this.visibleState.issue) {
                    chartData.datasets[1].data = [];
                }
                if(!this.visibleState.failed) {
                    chartData.datasets[2].data = [];
                }
                if(!this.visibleState.expired) {
                    chartData.datasets[3].data = [];
                }


                // Результат

                return chartData;

            },
        },
        methods: {
            
            close () {
                this.$refs.popup.close();
            },

            async show(filter, type) {
                this.type = type;
                this.recoverFilter(filter);
                this.$refs.popup.open();
                this.loadData();
            },

            recoverFilter (filter) {
                this.filter = {module: null, begin: null, end: null};
                if(filter.module) {
                    const idx = this.modules.findIndex((item) => { return item.id == filter.module});
                    if(idx !== -1) {
                        this.filter.module = this.modules[idx].id;
                    }
                }
                if(filter.begin && filter.end && this.$moment(filter.begin, 'YYYY-MM-DD', true).isValid() && this.$moment(filter.end, 'YYYY-MM-DD', true).isValid()) {
                    this.filter.begin = filter.begin;
                    this.filter.end = filter.end;
                } else {
                    this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
                    this.filter.end = this.$moment().format('YYYY-MM-DD');
                }
            },

            buildfilter () {
                const param = new URLSearchParams({});

                if(this.filter.module !== null) {
                    param.set("moduleNum", this.filter.module);
                }

                if(this.filter.begin !== null) {
                    param.set("begin", this.filter.begin + 'T00:00:00.802333+00:00');
                }

                if(this.filter.end !== null) {
                    param.set("end", this.filter.end + 'T23:59:59.802443+00:00');
                }

                param.set("actionType", this.type);
                
                return param;
            },

            calculateGroupings () {

                const begin = this.$moment(this.displayFilter.begin);
                const end = this.$moment(this.displayFilter.end);
                const difference = end.diff(begin, 'days');

                if (difference <= 15) {
                    
                    this.grouping = 1;
                    this.groupings[0].isDisabled = false;
                    this.groupings[1].isDisabled = true;
                    this.groupings[2].isDisabled = true;

                } else if (difference > 15 &&  difference <= 90) {

                    this.grouping = 2;
                    this.groupings[0].isDisabled = true;
                    this.groupings[1].isDisabled = false;
                    this.groupings[2].isDisabled = false;

                } else if (difference > 90 && difference <= 180) {

                    this.grouping = 3;
                    this.groupings[0].isDisabled = true;
                    this.groupings[1].isDisabled = false;
                    this.groupings[2].isDisabled = false;

                } else {

                    this.grouping = 3;
                    this.groupings[0].isDisabled = true;
                    this.groupings[1].isDisabled = false;
                    this.groupings[2].isDisabled = false;

                }

            },


            async clearFilter (e) {
                const btn = e.currentTarget;
                btn.classList.add('btn--load');
                
                this.filter.module = null;
                this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
                this.filter.end = this.$moment().format('YYYY-MM-DD');

                await this.loadData();
                btn.classList.remove('btn--load');
            },

            async saveFilter (e) {
                const btn = e.currentTarget;
                btn.classList.add('btn--load');
                
                await this.loadData();
                btn.classList.remove('btn--load');
            },

            async loadData () {

                const param = this.buildfilter();

                this.displayFilter = {...this.filter};

                this.calculateGroupings();

                const data = await this.$store.dispatch('getReportsHouseDynamics', {house_id: this.house_id, data: param});
                
                this.data = this.$helpers.transformDynamic(data);

            },

        },
    }
</script>

<style scoped>

    .chart {
        height: 270px;
    }
    .popup__header {
        font-size: 20px;
        padding: 20px 25px;
    }
    .popup__body {
        padding: 20px 25px;
    }
    .popup__footer {
        padding: 20px 40px;
    }

    .label {
        margin-top: 10px;
    }
    .field {
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 500;
    }

    .c-legend {
        width: 100%;
        justify-content: space-between;
    }

    .btn-group {
        padding: 20px 25px;
        border-bottom: 1px solid var(--cozh-light-grey);
        padding-top: 10px;
    }

    .component-filter {
        padding: 20px 25px;
        /*border-bottom: 1px solid var(--cozh-light-grey);*/
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding-bottom: 0px;
    }

    .component-filter__item {
        width: 100%;
    }

    .range-picker {
        min-width: 300px;
    }




</style>