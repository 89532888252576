<template>
	
	<div class="task" :class="'task--' + task.status">
		<div class="task__header">
			<div class="task__status" :style="{'color': statusColor}">
				<div class="task__badge">
					<IconWork v-if="task.actionType == 'work'" :color="statusColor" />
					<IconReview v-if="task.actionType == 'review'" :color="statusColor" />
					<IconControl v-if="task.actionType == 'control'" :color="statusColor" />
				</div>
				{{ statusText }}
			</div>
		</div>
		<div class="task__body">
			<div class="task__path" v-if="task.title !== ''">
				<div class="task__path-item" v-for="(path, index) in currentPath" :key="index">
					{{ path }}
				</div>
			</div>
			<a class="task__name" @click="openTask(task.id)">
				<HiddenText :text="task.name" :limited="90" />
			</a>
		</div>
		<div class="task__footer">
			<div class="task__num">
				{{ task.num }}
			</div>
			<div class="task__date">
				{{ $moment(task.dateCreated).format('DD.MM.YYYY') }}
			</div>
		</div>
	</div>

  <PanelTask ref="taskPanel" />

</template>

<script setup>
  import PanelTask from '../panels/PanelTask.vue';
  import HiddenText from '../ux/HiddenText.vue';

  import IconControl from '@/components/icons/task-types/IconControl.vue';
  import IconWork from '@/components/icons/task-types/IconWork.vue';
  import IconReview from '@/components/icons/task-types/IconReview.vue';
</script>

<script>
  export default {
    name: 'PageTrackerHouse',
    props: ['task', 'organization_id', 'house_id'],
		data: () => ({
    }),
    computed: {
    	statusText () {
    		switch (this.task.status) {
    			case 'assigned':
    				return 'Назначено';
    			case 'done':
    				return 'Всё в порядке';
    			case 'issue':
    				return 'Есть проблемы';
    			case 'failed':
    				return 'Не сделано';
    			case 'expired':
    				return 'Просрочено';
    			case 'new':
    				return 'Закрыто';
    			default:
    				return 'Назначено';
    		}
    	},
    	statusColor () {
    		switch (this.task.status) {
    			case 'assigned':
    				return 'var(--cozh-grey)';
    			case 'deferred':
    				return 'var(--cozh-grey)';
    			case 'done':
    				return 'var(--cozh-blue)';
    			case 'issue':
    				return 'var(--cozh-orange)';
    			case 'failed':
    				return 'var(--cozh-red)';
    			case 'expired':
    				return 'var(--cozh-dark-red)';
    			default:
    				return 'var(--cozh-grey)';
    		}
    	},
    	currentPath () {
    		return this.task.title.split(' / ');
    	},
    },
    created () {
    },
    methods: {
    	openTask (id) {
		    this.$refs.taskPanel.show({
          task_id: id,
          house_id: this.house_id,
          organization_id: this.organization_id,
        });
    	},
    },
  }
</script>

<style scoped>
	
	.task {
		padding: 15px;
		background-color: var(--cozh-white);
		border-radius: 8px;
		margin-bottom: 5px;
	}
	
	.task__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--cozh-light-grey);
		padding-bottom: 10px;
	}
	.task__footer {
		padding-top: 10px;
    border-top: 1px solid var(--cozh-light-grey);
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: space-between;
	}


	.task__status {
		font-size: 12px;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

	}
	.task__num {
		color: var(--cozh-grey);
		font-size: 12px;
		font-weight: 500;
	}
	.task__badge {
		height: 24px;
		width: 24px;
		border-radius: 50%;
		margin-right: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.task__badge svg {
		width: 12px;
	}
	.task__body {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.task__name {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.2;
		display: flex;
	}
	.task__date {
		color: rgba(var(--cozh-black-code), .5);
 		font-size: 12px;
 		font-weight: 500;
	}



	.task__path {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 8px;
	}
	.task__path-item {
		color: var(--cozh-grey);
 		font-size: 12px;
	 	font-weight: 500;
	 	max-width: 50%;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
	 	white-space: nowrap;
	 	position: relative;
	}
	.task__path-item:not(:last-child) {
		padding-right: 23px;
	 	max-width: calc(50% + 20px);
	}
	.task__path-item:not(:last-child)::before {
		content: url('../../assets/img/check-right-grey.svg');
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}



	.task--assigned .task__status {
		color: var(--cozh-grey);
	}
	.task--assigned .task__badge {
		background-color: rgba(233, 241, 244, 1);
	}
	.task--deferred .task__badge {
		background-color: rgba(233, 241, 244, 1);
	}
	.task--done .task__badge {
		background-color: rgba(233, 241, 244, 1);
	}
	.task--issue .task__badge {
		background-color: rgba(var(--cozh-orange-code), .12);
	}
	.task--failed .task__badge {
		background-color: rgba(var(--cozh-red-code), .12);
	}
	.task--expired .task__badge {
		background-color: rgba(var(--cozh-dark-red-code), .08);
	}



</style>