<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_38_5)">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8ZM5 11C5 8.23858 7.23858 6 10 6C12.7614 6 15 8.23858 15 11C15 13.7614 12.7614 16 10 16C7.23858 16 5 13.7614 5 11Z" :fill="color"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M0.120095 8.52485C1.14267 6.63118 4.49469 3 10 3C15.5053 3 18.8573 6.63118 19.8799 8.52485C20.1423 9.01081 19.9611 9.61749 19.4751 9.87991C18.9892 10.1423 18.3825 9.96111 18.1201 9.47515C17.3427 8.03548 14.5747 5 10 5C5.42531 5 2.65733 8.03548 1.87991 9.47515C1.61749 9.96111 1.01081 10.1423 0.524851 9.87991C0.038893 9.61749 -0.142323 9.01081 0.120095 8.52485Z" :fill="color"/>
		</g>
		<defs>
		<clipPath id="clip0_38_5">
			<rect width="20" height="20" fill="white"/>
		</clipPath>
		</defs>
	</svg>
</template>

<script>
  	export default {
    	name: 'IconReview',
    	props: ['color'],
    }
</script>
