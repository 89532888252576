<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_38_8)">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" :fill="color"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071L5.29289 11.7071C4.90237 11.3166 4.90237 10.6834 5.29289 10.2929C5.68342 9.90237 6.31658 9.90237 6.70711 10.2929L8 11.5858L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289Z" :fill="color"/>
		</g>
		<defs>
		<clipPath id="clip0_38_8">
		<rect width="20" height="20" fill="white"/>
		</clipPath>
		</defs>
	</svg>

</template>

<script>
  	export default {
    	name: 'IconControl',
    	props: ['color'],
    }
</script>
