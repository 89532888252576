<template>
		
	<div 
		class="calendar"
		:class="classObject"
		ref="calendar"
		@click="clickCalendar($event)"
	>

		<div class="calendar__control">
			<img class="calendar__ico" src="@/assets/img/task-icons/1.svg">
			<div class="calendar__label" v-if="displayLabel"> {{ label }} </div>
			<div class="calendar__value" v-if="modelValue !== null"> {{ $moment(modelValue).format('DD MMMM YYYY') }} </div>
			<transition name="slide">
			    <a class="custom-select__clear" v-if="isClear && modelValue !== null" @click.stop="clear">
			    	<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7 1.5L1 7.5M1 1.5L7 7.5" stroke="#AEC1CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
			    </a>
			</transition>
		</div>

    <transition name="slideY">
			<div class="calendar__content" v-if="isOpen">
				<Datepicker v-model="picked" :locale="localeRU"/>
				<div class="calendar__btn">
					<a @click="confirm" class="btn btn--default btn--sm btn--inline"> 
						<img class="btn--ico btn--ico--left" src="@/assets/img/check-white.svg">
						<div class="btn--text"> Выбрать </div>
						<div class="btn--loader"></div>
					</a>
				</div>
			</div>
		</transition>
	</div>
</template>


<script>
	
	import Datepicker from 'vue3-datepicker';
	import { ru } from 'date-fns/locale';

	export default {
	    
	    name: 'DatePicker',

	    components: { Datepicker },

	    props: [ 'modelValue', 'label', 'isClear', 'size', 'defaultTop' ],

	    data: () => ({
	    	isOpen: false,
	    	picked: null,
	    	date: null,
	    	localeRU: ru,
	    }),
	    
	    mounted () {
		    this.$nextTick(() => {
				window.addEventListener('click', this.clickEvent);
		    })
	    },

	    unmounted () {
	    	window.removeEventListener('click', this.clickEvent);
			},

			computed: {
				classObject () {
	    		const classes = {
	    			'calendar--open': this.isOpen, 
	    		}
	    		if(this.defaultTop) {
	    			classes['calendar--top'] = true;
	    		}
	    		if(this.size) {
	    			classes['calendar--' + this.size] = true;
	    		}
	    		return classes;
	    	},
	    	displayLabel () {
	    		if(typeof this.size === 'undefined' || (typeof this.size !== 'undefined' && this.modelValue == null)) {
	    			return true;
	    		} else {
	    			return false;
	    		}
	    	},
			},

	    methods: {
	    	confirm () {
	    		let date = this.picked !== null ? this.$moment(this.picked).format('YYYY-MM-DD') : null;
	    		this.$emit('update:modelValue', date);
			  	this.isOpen = false;
	    	},
	    	
	    	clear () {
	    		this.picked = null;
	    		this.$emit('update:modelValue', null);
			  	this.isOpen = false;
	    	},

	    	clickEvent (e) {
	    		// Только если открыт
			  	if(this.isOpen) {
			  		// Элемент на который нажали находится за пределами этого селекта
				  	if(!this.$refs.calendar.contains(e.target)) {
				  		// Закрываем этот селект
				  		this.isOpen = false;
				  	}
			  	}
	    	},
	    	// Клик на селект
	    	clickCalendar (e) {
	    		if(!this.$refs.calendar.contains(e.target)) {
			  		// Закрываем этот селект
			  		this.isOpen = false;
			  	} else {
			  		this.isOpen = true;
			  		this.picked = this.modelValue !== null ? new Date(this.modelValue) : null;
			  	}
	    	},

	    },
	};
</script>


<style scoped>

	.calendar.calendar--sm {

	}
	.calendar.calendar--sm .calendar__control {
		height: 40px;
	  padding: 0px 15px;
    padding-top: 0px;
	  background-color: #fff;
	  border-radius: 4px;
	  border: 1px solid var(--cozh-light-grey);
	}
	.calendar.calendar--sm .calendar__label {
		color: var(--cozh-grey);
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0em;
    top: 50%;
    transform: translateY(-50%);
	}
	.calendar.calendar--sm .calendar__value {
		color: var(--cozh-black);
    text-overflow: ellipsis;
    font-weight: 500;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    display: block;
    line-height: 1;
    font-size: 12px;
	}
	.calendar.calendar--sm:hover .calendar__control {
		border-color: var(--cozh-grey);
		background-color: #fff;
	}
	.calendar.calendar--sm .calendar__content {
    top: 45px;
	}
	.calendar.calendar--sm .calendar__ico {
		height: 17px;
		width: 17px;
		right: 10px;
	}


	.calendar {
		position: relative;
	}
	.calendar.calendar--open .calendar__control {
		border-color: var(--cozh-grey);
	}

	.calendar:hover .calendar__control {
		background-color: rgb(174 193 204 / 35%);
	}

	.calendar__control {
		height: 62px;
	  padding: 0px 20px;
    padding-top: 20px;
	  background-color: rgba(240, 245, 248, 1);
	  border-radius: 8px;
	  cursor: pointer;
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  position: relative;
	  transition: all .2s ease-out;
	  border: 1px solid transparent;
	}

	.calendar__label {
    position: absolute;
    left: 20px;
    top: 15px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(var(--cozh-black-code), .35);
    user-select: none;
    pointer-events: none;
	}

	.calendar__value {
    font-size: 18px;
    color: var(--cozh-black);
    text-overflow: ellipsis;
    font-weight: 600;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    display: block;
    line-height: 1.3;
    user-select: none;
    pointer-events: none;	
  }

	.calendar__content {
		max-width: 300px;
    background: #fff;
    padding: 0px;
    border-radius: 8px;
    position: absolute;
    left: 0px;
    top: 68px;
    box-shadow: 0px 5px 20px 0px rgba(11, 40, 54, 0.18);
    overflow: hidden;
    z-index: 99;
	}

	.calendar--top .calendar__content {
		top: auto;
    bottom: 68px;
	}


	.calendar__btn {
		margin-top: 0px;
		padding: 20px;
	}
	.calendar__ico {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}


	.custom-select__clear {
		position: absolute;
	    background-color: var(--cozh-white);
	    border-radius: 50%;
	    top: -8px;
	    right: -7px;
	    border: 1px solid var(--cozh-light-grey);
	    height: 20px;
	    width: 20px;
	    display: flex;
	    flex-direction: revert;
	    align-items: center;
	    justify-content: center;
	    transition: all .2s ease-out;
	    z-index: 1;
	}

	.custom-select__clear path {
		transition: all .2s ease-out;
	}
	.custom-select__clear:hover {
		border-color: transparent;
		background-color: var(--cozh-blue);
	}
	.custom-select__clear:hover path {
		stroke: var(--cozh-white);
	}


  .slide-enter-active {
    transition: all 0.2s ease-out;
  }
  .slide-leave-active {
    transition: all 0.2s ease-out;
  }
  .slide-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
  .slide-enter-from {
    transform: translateX(-20px);
    opacity: 0;
  }









	@media (max-width: 767px) {

	}
</style>