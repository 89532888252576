<template>
	<div class="activity">
		<div class="activity__element">
			<div class="activity__item"></div>
			<div class="activity__item" style="margin-top: 5px;">Для всего топика: </div>
			<div class="activity__item"></div>
			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите периодичность'" 
					:size="'xs'"
					:list="periodical"
					v-model="currTopic.periodicalValue"
					@update:model-value="setTopicPeriodical"
				/>
			</div>
			<div class="activity__item">
				<!-- Периодичность указана как "Ежедневно" -->
				<HoursPicker
					:label="'Выберите время'" 
					v-model="currTopic.period"
					v-if="currTopic.periodicalValue == 'everyDay'"
					@update:model-value="setTopicPeriod"
				/>
				<!-- Периодичность указана как "Еженедельно" -->
				<WeekDayPicker
					:label="'Выберите дни недели'" 
					v-model="currTopic.period"
					v-if="currTopic.periodicalValue == 'everyWeek'"
					@update:model-value="setTopicPeriod"
				/>
				<!-- Периодичность указана как "Ежемесячно" -->
				<MonthDayPicker
					:label="'Выберите дни месяца'" 
					v-model="currTopic.period"
					v-if="currTopic.periodicalValue == 'everyMonth'"
					@update:model-value="setTopicPeriod"
				/>
				<!-- Периодичность указана как "Ежеквартально" -->
				<QuarterDayPicker
					:label="'Укажите дни квартала'" 
					v-model="currTopic.period"
					v-if="currTopic.periodicalValue == 'everyQuarter'"
					@update:model-value="setTopicPeriod"
				/>
				<!-- Периодичность указана как "Каждые пол года" -->
				<SixMonthDayPicker
					:label="'Укажите дни полугодий'" 
					v-model="currTopic.period"
					v-if="currTopic.periodicalValue == 'everySixMonths'"
					@update:model-value="setTopicPeriod"
				/>
				<!-- Периодичность указана как "Каждый год" -->
				<YearDayPicker
					:label="'Укажите дни в году'" 
					v-model="currTopic.period"
					v-if="currTopic.periodicalValue == 'everyYear'"
					@update:model-value="setTopicPeriod"
				/>
			</div>
			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите квалификацию'" 
					:size="'xs'"
					:list="functions"
					v-model="currTopic.functionId"
					@update:model-value="setTopicFunctionId"
				/>
			</div>
			<div class="activity__item">
				<SelectPicker
					:label="'Выберите сотрудника'"
					:size="'xs'"
					:list="employeesList"
					v-if="currTopic.functionId && currTopic.functionId !== null"
					v-model="currTopic.employeeId"
					@update:model-value="setTopicEmploeeId"
				/>
			</div>
			<div class="activity__item">
				<div class="form-control form-control--xs">
					<input 
						type="number" 
						v-model="currTopic.ttl"
						@update:model-value="setTopicTtl"
						style="text-align: center;"
						placeholder="0"
					> 
				</div>
			</div>
		</div>
	</div>

	<div class="activity" v-for="activity in topic.activities" :key="activity.num">

		<!-- Заголовок Activity -->
		<div class="activity__header">
			<span> Индекс </span> 
			<span class="activity__num"> {{ activity.num }} </span>
			<div class="switcher">
				<input type="checkbox" v-model="activity.active">
			</div>
			<div class="activity__hint"> {{ activity.doc }} </div>
		</div>





		<!-- Осмотр -->
		<div class="activity__element" v-if="activity.review !== null">
			<div class="activity__item">
				<div class="activity__ico">О</div>
			</div>
			<div class="activity__item" style="margin-top: 5px;">
				{{ activity.review }}
			</div>
			<div class="activity__item">
				{{ activity.location }}
			</div>
			<div class="activity__item">
				<!-- Если необходимо указывать периодичность осмотра -->
				<div v-if="activity.reviewPeriodical">
					<SelectPicker 
						:label="'Выберите периодичность'" 
						:size="'xs'"
						:list="periodical"
						v-model="activity.reviewPeriodicalValue"
						@update:modelValue="(val) => { 
							activity.reviewPeriod = {[val]:[]};
							currTopic.periodicalValue = null;
							currTopic.period = []; 
							hideError(activity.error, 'reviewPeriod')
						}"
					/>
				</div>
			</div>
			<div class="activity__item">
				<!-- Если необходимо указывать периодичность осмотра -->
				<div 
					v-if="activity.reviewPeriodical"
					class="form-group"
					:class="{'error': activity.error && activity.error.includes('reviewPeriod')}">
					<!-- Периодичность указана как "Ежедневно" -->
					<HoursPicker 
						:label="'Выберите время'" 
						v-model="activity.reviewPeriod[activity.reviewPeriodicalValue]"
						v-if="activity.reviewPeriodicalValue == 'everyDay'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'reviewPeriod')
						}"
					/>
					<!-- Периодичность указана как "Еженедельно" -->
					<WeekDayPicker 
						:label="'Выберите дни недели'" 
						v-model="activity.reviewPeriod[activity.reviewPeriodicalValue]"
						v-if="activity.reviewPeriodicalValue == 'everyWeek'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'reviewPeriod')
						}"
					/>
					<!-- Периодичность указана как "Ежемесячно" -->
					<MonthDayPicker 
						:label="'Выберите дни месяца'" 
						v-model="activity.reviewPeriod[activity.reviewPeriodicalValue]"
						v-if="activity.reviewPeriodicalValue == 'everyMonth'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'reviewPeriod')
						}"
					/>
					<!-- Периодичность указана как "Ежеквартально" -->
					<QuarterDayPicker 
						:label="'Укажите дни квартала'" 
						v-model="activity.reviewPeriod[activity.reviewPeriodicalValue]"
						v-if="activity.reviewPeriodicalValue == 'everyQuarter'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'reviewPeriod')
						}"
					/>
					<!-- Периодичность указана как "Каждые пол года" -->
					<SixMonthDayPicker 
						:label="'Укажите дни полугодий'" 
						v-model="activity.reviewPeriod[activity.reviewPeriodicalValue]"
						v-if="activity.reviewPeriodicalValue == 'everySixMonths'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'reviewPeriod')
						}"
					/>
					<!-- Периодичность указана как "Каждый год" -->
					<YearDayPicker 
						:label="'Укажите дни в году'" 
						v-model="activity.reviewPeriod[activity.reviewPeriodicalValue]"
						v-if="activity.reviewPeriodicalValue == 'everyYear'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'reviewPeriod')
						}"
					/>
				</div>
			</div>
			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите квалификацию'" 
					:size="'xs'"
					:list="functions"
					v-model="activity.reviewFunction.id"
					@update:model-value="(val) => { 
						activity.reviewEmployeeId = null;
						currTopic.functionId = null;
					}"
				/>
			</div>
			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите сотрудника'" 
					:size="'xs'"
					:list="employeesListReview(activity)"
					v-model="activity.reviewEmployeeId" 
					@update:modelValue="(val) => {
						currTopic.employeeId = null;
					}"
				/>
			</div>
			<div class="activity__item">
				<div class="form-group" :class="{'error': activity.error && activity.error.includes('reviewTtl')}">
					<div class="form-control form-control--xs">
						<input 
							type="number" 
							v-model="activity.reviewTtl" 
							@focus="() => { hideError(activity.error, 'reviewTtl') }"
							@input="(val) => { currTopic.ttl = null }"
							style="text-align: center;"
						> 
					</div>
				</div>
			</div>
		</div>


		<!-- Работа -->
		<div class="activity__element" v-if="activity.work !== null">
			<div class="activity__item">
				<div class="activity__ico">Р</div>
			</div>
			<div class="activity__item" style="margin-top: 5px;">
				{{ activity.work }}
			</div>
			<div class="activity__item">
				{{ activity.location }}
			</div>
			<div class="activity__item">
				<!-- Если необходимо указывать периодичность осмотра -->
				<div v-if="activity.workPeriodical">
					<SelectPicker
						:label="'Выберите периодичность'"
						:size="'xs'"
						:list="periodical"
						v-model="activity.workPeriodicalValue"
						@update:modelValue="(val) => { 
							activity.workPeriod = {[val]:[]}; 
							currTopic.periodicalValue = null;
							currTopic.period = []; 
							hideError(activity.error, 'workPeriod')
						}"
					/>
				</div>
			</div>

			<div class="activity__item">
				<!-- Если необходимо указывать периодичность осмотра -->
				<div 
					v-if="activity.workPeriodical"
					class="form-group"
					:class="{'error': activity.error && activity.error.includes('workPeriod')}">
					<!-- Периодичность указана как "Ежедневно" -->
					<HoursPicker 
						:label="'Выберите время'" 
						v-model="activity.workPeriod[activity.workPeriodicalValue]"
						v-if="activity.workPeriodicalValue == 'everyDay'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'workPeriod')
						}"
					/>
					<!-- Периодичность указана как "Еженедельно" -->
					<WeekDayPicker 
						:label="'Выберите дни недели'" 
						v-model="activity.workPeriod[activity.workPeriodicalValue]"
						v-if="activity.workPeriodicalValue == 'everyWeek'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'workPeriod')
						}"
					/>
					<!-- Периодичность указана как "Ежемесячно" -->
					<MonthDayPicker 
						:label="'Выберите дни месяца'" 
						v-model="activity.workPeriod[activity.workPeriodicalValue]"
						v-if="activity.workPeriodicalValue == 'everyMonth'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'workPeriod')
						}"
					/>
					<!-- Периодичность указана как "Ежеквартально" -->
					<QuarterDayPicker 
						:label="'Укажите дни квартала'" 
						v-model="activity.workPeriod[activity.workPeriodicalValue]"
						v-if="activity.workPeriodicalValue == 'everyQuarter'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'workPeriod')
						}"
					/>
					<!-- Периодичность указана как "Каждые пол года" -->
					<SixMonthDayPicker 
						:label="'Укажите дни полугодий'" 
						v-model="activity.workPeriod[activity.workPeriodicalValue]"
						v-if="activity.workPeriodicalValue == 'everySixMonths'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'workPeriod')
						}"
					/>
					<!-- Периодичность указана как "Каждый год" -->
					<YearDayPicker 
						:label="'Укажите дни в году'" 
						v-model="activity.workPeriod[activity.workPeriodicalValue]"
						v-if="activity.workPeriodicalValue == 'everyYear'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'workPeriod')
						}"
					/>
				</div>
			</div>

			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите квалификацию'" 
					:size="'xs'"
					:list="functions" 
					v-model="activity.workFunction.id"
					@update:model-value="(val) => { 
						currTopic.functionId = null;
						activity.workEmployeeId = null 
					}"
				/>
			</div>
			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите сотрудника'" 
					:size="'xs'"
					:list="employeesListWork(activity)" 
					v-model="activity.workEmployeeId"
					@update:modelValue="(val) => {
						currTopic.employeeId = null;
					}"
				/>
			</div>
			<div class="activity__item">
				<div class="form-group" :class="{'error': activity.error && activity.error.includes('workTtl')}">
					<div class="form-control form-control--xs">
						<input 
							type="number" 
							v-model="activity.workTtl" 
							@focus="() => { hideError(activity.error, 'workTtl') }"
							@input="(val) => { currTopic.ttl = null }"
							style="text-align: center;"
						> 
					</div>
				</div>
			</div>
		</div>

		<!-- Контроль -->
		<div class="activity__element" v-if="activity.control !== null">
			<div class="activity__item">
				<div class="activity__ico">К</div>
			</div>
			<div class="activity__item" style="margin-top: 5px;">
				{{ activity.control }}
			</div>
			<div class="activity__item">
				{{ activity.location }}
			</div>
			<div class="activity__item">
				<!-- Если необходимо указывать периодичность осмотра -->
				<div v-if="activity.controlPeriodical">
					<SelectPicker
						:label="'Выберите периодичность'"
						:size="'xs'"
						:list="periodical"
						v-model="activity.controlPeriodicalValue"
						@update:modelValue="(val) => { 
							activity.controlPeriod = {[val]:[]};
							currTopic.periodicalValue = null;
							currTopic.period = []; 
							hideError(activity.error, 'controlPeriod') 
						}"
					/>
				</div>
			</div>
			<div class="activity__item">
				<!-- Если необходимо указывать периодичность осмотра -->
				<div 
					v-if="activity.controlPeriodical"
					class="form-group"
					:class="{'error': activity.error && activity.error.includes('controlPeriod')}">
					<!-- Периодичность указана как "Ежедневно" -->
					<HoursPicker 
						:label="'Выберите время'" 
						v-model="activity.controlPeriod[activity.controlPeriodicalValue]"
						v-if="activity.controlPeriodicalValue == 'everyDay'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'controlPeriod')
						}"
					/>
					<!-- Периодичность указана как "Еженедельно" -->
					<WeekDayPicker 
						:label="'Выберите дни недели'" 
						v-model="activity.controlPeriod[activity.controlPeriodicalValue]"
						v-if="activity.controlPeriodicalValue == 'everyWeek'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'controlPeriod')
						}"
					/>
					<!-- Периодичность указана как "Ежемесячно" -->
					<MonthDayPicker 
						:label="'Выберите дни месяца'" 
						v-model="activity.controlPeriod[activity.controlPeriodicalValue]"
						v-if="activity.controlPeriodicalValue == 'everyMonth'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'controlPeriod')
						}"
					/>
					<!-- Периодичность указана как "Ежеквартально" -->
					<QuarterDayPicker 
						:label="'Укажите дни квартала'" 
						v-model="activity.controlPeriod[activity.controlPeriodicalValue]"
						v-if="activity.controlPeriodicalValue == 'everyQuarter'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'controlPeriod')
						}"
					/>
					<!-- Периодичность указана как "Каждые пол года" -->
					<SixMonthDayPicker 
						:label="'Укажите дни полугодий'" 
						v-model="activity.controlPeriod[activity.controlPeriodicalValue]"
						v-if="activity.controlPeriodicalValue == 'everySixMonths'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'controlPeriod')
						}"
					/>
					<!-- Периодичность указана как "Каждый год" -->
					<YearDayPicker 
						:label="'Укажите дни в году'" 
						v-model="activity.controlPeriod[activity.controlPeriodicalValue]"
						v-if="activity.controlPeriodicalValue == 'everyYear'"
						@update:modelValue="(val) => {
							currTopic.period = []; 
							hideError(activity.error, 'controlPeriod')
						}"
					/>
				</div>
			</div>
			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите квалификацию'" 
					:size="'xs'"
					:list="functions" 
					v-model="activity.controlFunction.id" 
					@update:model-value="(val) => { 
						activity.controlEmployeeId = null;
						currTopic.functionId = null;
					}"
				/>
			</div>
			<div class="activity__item">
				<SelectPicker 
					:label="'Выберите сотрудника'" 
					:size="'xs'"
					:list="employeesListControl(activity)" 
					v-model="activity.controlEmployeeId" 
					@update:modelValue="(val) => {
						currTopic.employeeId = null;
					}"
				/>
			</div>
			<div class="activity__item">
				<div class="form-group" :class="{'error': activity.error && activity.error.includes('controlTtl')}">
					<div class="form-control form-control--xs">
						<input 
							type="number" 
							v-model="activity.controlTtl" 
							@focus="() => { hideError(activity.error, 'controlTtl') }"
							@input="(val) => { currTopic.ttl = null }"
							style="text-align: center;"
						> 
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
  import SelectPicker from '@/components/ux/SelectPicker.vue';
  import HoursPicker from '@/components/ux/HoursPicker.vue';
  import WeekDayPicker from '@/components/ux/WeekDayPicker.vue';
  import MonthDayPicker from '@/components/ux/MonthDayPicker.vue';
  import QuarterDayPicker from '@/components/ux/QuarterDayPicker.vue';
  import SixMonthDayPicker from '@/components/ux/SixMonthDayPicker.vue';
  import YearDayPicker from '@/components/ux/YearDayPicker.vue';
</script>

<script>
	export default {
		name: 'ActivityComponent',
		props: ['topic', 'functions', 'employees'],
	    data: () => ({
	    	periodical: [
	    		{ id: 'everyDay', name: 'Ежедневно' },
	    		{ id: 'everyWeek', name: 'Еженедельно' },
	    		{ id: 'everyMonth', name: 'Ежемесячно' },
	    		{ id: 'everyQuarter', name: 'Ежеквартально' },
	    		{ id: 'everySixMonths', name: 'Каждые пол года' },
	    		{ id: 'everyYear', name: 'Ежегодно' },
	    	],
	    }),

	    created () {

	  	  this.currTopic.periodicalValue = null;
				this.currTopic.period = null;
				this.currTopic.functionId = null;
				this.currTopic.employeeId = null;
				this.currTopic.ttl = null;

	    	for (let key in this.topic.activities) {
	    		
	    		// Активность
	    		let activity = this.topic.activities[key];

	    		let listEmployers = [];

	    		// Есть работа
	    		if(activity.work !== null) {
	    			// Возможный список сотрудников
	    			listEmployers = this.employeesListWork(activity);
	    			// Устанавливаем если один
	    			if(listEmployers.length == 1) activity.workEmployeeId = listEmployers[0].id;
	    			// У активности есть периодичность
	    			if (activity.workPeriodical) {
	    				// Ставим значение периодичности
		    			activity.workPeriodicalValue = Object.keys(activity.workPeriod)[0];
		    		}
	    		}

	    		// Есть осмотр
	    		if(activity.review !== null) {
	    			// Возможный список сотрудников
	    			listEmployers = this.employeesListReview(activity);
	    			// Устанавливаем если один
	    			if(listEmployers.length == 1) activity.reviewEmployeeId = listEmployers[0].id;
	    			// У активности есть периодичность
	    			if (activity.reviewPeriodical) {
	    				// Ставим значение периодичности
		    			activity.reviewPeriodicalValue = Object.keys(activity.reviewPeriod)[0];
		    		}
	    		}

	    		// Есть контроль
	    		if(activity.control !== null) {
	    			// Возможный список сотрудников
	    			listEmployers = this.employeesListControl(activity);
	    			// Устанавливаем если один
	    			if(listEmployers.length == 1) activity.controlEmployeeId = listEmployers[0].id;
	    			// У активности есть периодичность
	    			if (activity.controlPeriodical) {
	    				// Ставим значение периодичности
	    				activity.controlPeriodicalValue = Object.keys(activity.controlPeriod)[0];
	    			}
	    		}
	    	}

	    },


	    computed: {
	    	currTopic () {
	    		return this.topic;
	    	},
	    	
	    	employeesList () {
	  			let employees = [];
	    		this.employees.forEach((item) => {
	    			if(!item.active) { return }
	    			let employerFuncsArr = [];
		    		item.functions.forEach((func) => { employerFuncsArr.push(func.id) });
		    		if(employerFuncsArr.includes(this.currTopic.functionId)) {
	    				employees.push({ id: item.id, name: item.name });
	    			}
	    		});
	    		return employees;
	    	},

	    	employeesListControl () {
	    		return (activity) => {
		    		let employees = [];
		    		if (activity.controlFunction && activity.controlFunction != null) {
			    		this.employees.forEach((item) => {
			    			if(!item.active) { return }
			    			let employerFuncsArr = [];
			    			item.functions.forEach((func) => { employerFuncsArr.push(func.id) });
			    			if(employerFuncsArr.includes(activity.controlFunction.id)) {
			    				employees.push({ id: item.id, name: item.name });
			    			}
			    		});
		    		}
		    		return employees;
	    		}
	    	},
	    	employeesListWork () {
	    		return (activity) => {
		    		let employees = [];
		    		if (activity.workFunction && activity.workFunction != null) {
			    		this.employees.forEach((item) => {
			    			if(!item.active) { return }
			    			let employerFuncsArr = [];
			    			item.functions.forEach((func) => { employerFuncsArr.push(func.id) });
			    			if(employerFuncsArr.includes(activity.workFunction.id)) {
			    				employees.push({ id: item.id, name: item.name });
			    			}
			    		});
		    		}
		    		return employees;
	    		}
	    	},
	    	employeesListReview () {
	    		return (activity) => {
		    		let employees = [];
		    		if (activity.reviewFunction && activity.reviewFunction != null) {
			    		this.employees.forEach((item) => {
			    			if(!item.active) { return }
			    			let employerFuncsArr = [];
			    			item.functions.forEach((func) => { employerFuncsArr.push(func.id) });
			    			if(employerFuncsArr.includes(activity.reviewFunction.id)) {
			    				employees.push({ id: item.id, name: item.name });
			    			}
			    		});
		    		}
		    		return employees;
	    		}
	    	},
	    },
	    methods: {
	    	hideError (arr, prop) {
				if(!arr) return;
				const idx = arr.indexOf(prop);
				if(idx !== -1) {
					arr.splice(idx, 1);
				}  
	    	},
	    	setTopicPeriodical () {
	    		this.currTopic.period = [];
				for (const key in this.currTopic.activities) {
					let activity = this.currTopic.activities[key];
					if(activity.control && activity.controlPeriodical) {
						if(activity.controlPeriodicalValue !== this.currTopic.periodicalValue) {
							activity.controlPeriodicalValue = this.currTopic.periodicalValue;
    						activity.controlPeriod = { [this.currTopic.periodicalValue]: [] };
    						this.hideError(activity.error, 'controlPeriod');
						}
					}
					if(activity.review && activity.reviewPeriodical) {
						if(activity.reviewPeriodicalValue !== this.currTopic.periodicalValue) {
							activity.reviewPeriodicalValue = this.currTopic.periodicalValue;
    						activity.reviewPeriod = { [this.currTopic.periodicalValue]: [] };
    						this.hideError(activity.error, 'reviewPeriod');
						}
					}
					if(activity.work && activity.workPeriodical) {
						if(activity.workPeriodicalValue !== this.currTopic.periodicalValue) {
							activity.workPeriodicalValue = this.currTopic.periodicalValue;
	    					activity.workPeriod = { [this.currTopic.periodicalValue]: [] };
	    					this.hideError(activity.error, 'workPeriod');
						}
					}
				}
	    	},
	    	setTopicPeriod () {
				for (const key in this.currTopic.activities) {
					let activity = this.currTopic.activities[key];
					if(activity.control && activity.controlPeriodical) {
						activity.controlPeriod[this.currTopic.periodicalValue] = this.currTopic.period
    					this.hideError(activity.error, 'controlPeriod');
					}
					if(activity.review && activity.reviewPeriodical) {
						activity.reviewPeriod[this.currTopic.periodicalValue] = this.currTopic.period
    					this.hideError(activity.error, 'reviewPeriod');
					}
					if(activity.work && activity.workPeriodical) {
						activity.workPeriod[this.currTopic.periodicalValue] = this.currTopic.period
    					this.hideError(activity.error, 'workPeriod');
					}
				}
	    	},

	    	setTopicFunctionId () {
				this.currTopic.employeeId = null;
				for (const key in this.currTopic.activities) {
					let activity = this.currTopic.activities[key];
					if(activity.control) {
						if(activity.controlFunction.id !== this.currTopic.functionId) {
							activity.controlFunction.id = this.currTopic.functionId;
							activity.controlEmployeeId = null;
						}
					}
					if(activity.review) {
						if(activity.reviewFunction.id !== this.currTopic.functionId) {
							activity.reviewFunction.id = this.currTopic.functionId;
							activity.reviewEmployeeId = null;
						}
					}
					if(activity.work) {
						if(activity.workFunction.id !== this.currTopic.functionId) {
							activity.workFunction.id = this.currTopic.functionId;
							activity.workEmployeeId = null;
						}
					}
				}
	    	},

	    	setTopicEmploeeId () {
				for (const key in this.currTopic.activities) {
					let activity = this.currTopic.activities[key];
					if(activity.control) {
						activity.controlEmployeeId = this.currTopic.employeeId;
					}
					if(activity.review) {
						activity.reviewEmployeeId = this.currTopic.employeeId;
					}
					if(activity.work) {
						activity.workEmployeeId = this.currTopic.employeeId;
					}
				}
	    	},

	    	setTopicTtl () {
				for (const key in this.currTopic.activities) {
					let activity = this.currTopic.activities[key];
					if(activity.control) {
						activity.controlTtl = this.currTopic.ttl;
    					this.hideError(activity.error, 'controlTtl');
					}
					if(activity.review) {
						activity.reviewTtl = this.currTopic.ttl;
    					this.hideError(activity.error, 'reviewTtl');
					}
					if(activity.work) {
						activity.workTtl = this.currTopic.ttl;
    					this.hideError(activity.error, 'workTtl');
					}
				}
	    	},

	    },
	}
</script>

<style scoped>
	
	.activity {
		padding: 0px 20px;
	}

	.activity:not(:last-child) {
		border-bottom: 1px solid var(--cozh-light-grey);
	}

	.activity__hint {
		font-size: 12px;
		color: var(--cozh-dark-grey);
		margin-left: 15px;
	}

	.activity__header {
		padding: 10px 0px;
    border-bottom: 1px solid var(--cozh-light-grey);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
	}


	.activity__header span:first-child {
		color: var(--cozh-grey);
		font-size: 12px;
		font-weight: 500;
		margin-right: 8px;
	}

	.activity__header .activity__num {
		color: var(--cozh-black);
 		font-size: 12px;
 		font-weight: 500;
	}

	.activity__header .switcher {
		margin-left: 10px;
	}

	.activity__element {
		padding: 10px 0px;

		display: flex;
		flex-direction: row;
		align-items: flex-start;
	    align-self: stretch;
	    flex-shrink: 0;
	    gap: 10px;
	    justify-content: flex-start;
	    position: relative;

	}
	.activity__element:not(:last-child) {
		border-bottom: 1px solid var(--cozh-light-grey);
	}

	.activity__ico {
		height: 24px;
		width: 24px;
		color: #94A9B6;
		font-size: 10px;
		font-weight: 600;
		background-color: #e9f1f4;
		border-radius: 50%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.activity__item {
		font-size: 12px;
		font-weight: 500;
	}

	.activity__item:nth-child(8) {
		min-width: 60px;
		max-width: 60px;
	}
	.activity__item:nth-child(7) {
		min-width: 150px;
		max-width: 150px;
	}
	.activity__item:nth-child(6) {
		min-width: 150px;
		max-width: 150px;
	}
	.activity__item:nth-child(5) {
		min-width: 150px;
		max-width: 150px;
	}
	.activity__item:nth-child(4) {
		min-width: 150px;
		max-width: 150px;
	}
	.activity__item:nth-child(3) {
		min-width: 150px;
		max-width: 150px;
	}
	.activity__item:nth-child(2) {
		width: 100%;
	}
	.activity__item:nth-child(1) {
		min-width: 30px;
		max-width: 30px;
	}

</style>