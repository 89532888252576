<template>
    <panel-modal ref="popup" @close="close">
        <EmployeeInner :employee_id="employee_id" :begin="begin" :end="end" />
    </panel-modal>
</template>

<script>

    import PanelModal from '@/components/panels/PanelModal.vue'
    import EmployeeInner from '@/components/employee/EmployeeInner.vue';

    export default {

        name: 'PanelTask',

        components: { PanelModal, EmployeeInner },

        data: () => ({
            employee_id: null,
            begin: null,
            end: null,
        }),

        methods: {
            close () {
                this.$refs.popup.close();
            },
            show(opts = {}) {
                this.employee_id = opts.employee_id;
                this.begin = opts.begin;
                this.end = opts.end;
                this.$refs.popup.open();
            },
        },
    }
</script>

<style scoped>

</style>