<template>

  	<TopMenu :breadcrumbs="breadcrumbs"/>

		<div class="c-row">
			<div class="block block--medium">
	  		<div class="block__header">
	  			<div class="block__header-left">
		    		Лучший показатель недели
	    		</div>
	    		<div class="block__header-right">
	    			<div class="text-help"> {{ topDates.from.format('DD.MM.YYYY') + ' — ' + topDates.to.format('DD.MM.YYYY')}} </div>
	    		</div>
	  		</div>
	  		<div class="block__body">
					<BestIndicator 
						:organization_id="organization_id" 
						:begin="topDates.from.format('YYYY-MM-DDT00:00:00')"
						:end="topDates.to.format('YYYY-MM-DDT23:59:59')"
					/>
	  		</div>
	  	</div>

			<div class="block block--medium">
	  		<div class="block__header">
	  			<div class="block__header-left">
		    		Худший показатель недели
	    		</div>
	    		<div class="block__header-right">
	    			<div class="text-help"> {{ topDates.from.format('DD.MM.YYYY') + ' — ' + topDates.to.format('DD.MM.YYYY')}} </div>
	    		</div>
	  		</div>
	  		<div class="block__body">
					<WorstIndicator 
						:organization_id="organization_id"
						:begin="topDates.from.format('YYYY-MM-DDT00:00:00')"
						:end="topDates.to.format('YYYY-MM-DDT23:59:59')"
					/>
	  		</div>
	  	</div>
		</div>
  	
		<div class="load-container" :class="{'load' : isLoad}">

			<template v-if="isLoad == false">
				
		    <div class="filter">
		    	<div class="filter__header">
		    		Доля выполненных заданий
		    	</div>
		    	<div class="filter__body">
				    <div class="range-picker">
				      <DatePicker v-model="filter.begin" label="Дата от" :isClear="false" size="sm" />
				      <DatePicker v-model="filter.end" label="Дата до" :isClear="false" size="sm" />
				    </div>
				    <div class="btn-group">
				      <a class="btn btn--medium btn--default btn--inline" @click="saveFilter($event)">
				        <div class="btn--text"> Применить фильтры </div>
				        <div class="btn--loader"> </div>
				      </a>
				      <a class="btn btn--medium btn--outline btn--inline" @click="clearFilter($event)">
				        <div class="btn--text"> Сбросить фильтры </div>
				        <div class="btn--loader"> </div>
				      </a>
				    </div>
		    	</div>
		    	<div class="filter__content">
		    		
		    		<!-- Дома -->
		    		<div class="block c-block block--border block--medium">
				    	<div class="block__header">
				    		<div class="block__header-left">
				    			В разрезе объектов
				    		</div>
				    		<div class="block__header-right">
				    			<div class="text-help"> {{ $moment(displayFilter.begin).format('DD.MM.YYYY') + ' — ' + $moment(displayFilter.end).format('DD.MM.YYYY')}} </div>
				    		</div>
				    	</div>
				    	<div class="block__body">
				    		<HousesInfo :organization_id="organization_id" :begin="displayFilter.begin" :end="displayFilter.end" ref="housesInfo"/>
					    	<!-- Тут была схема -->
				    		<!-- <div class="block__content"></div> -->
				    	</div>
				    </div>

		    		<!-- Сотрудники -->
				    <div class="block c-block block--border block--medium">
				    	<div class="block__header">
				    		<div class="block__header-left">
				    			В разрезе сотрудников
				    		</div>
				    		<div class="block__header-right">
				    			<div class="text-help"> {{ $moment(displayFilter.begin).format('DD.MM.YYYY') + ' — ' + $moment(displayFilter.end).format('DD.MM.YYYY')}} </div>
				    		</div>
				    	</div>
				    	<div class="block__body">
				    		<EmployersInfo :organization_id="organization_id" :begin="displayFilter.begin" :end="displayFilter.end" ref="employersInfo"/>
				    		<!-- Тут была схема -->
				    		<!-- <div class="block__content"></div> -->
				    	</div>
				    </div>
		    	</div>
			  </div>

			</template>

	  </div>


	  <div class="block c-block block--medium">
	  	<div class="block__header">
  			Динамика выполнения заданий в разрезе объектов
	  	</div>
	  	<div class="block__body">
    		<GraphHouses :organization_id="organization_id" />
	  	</div>
	  </div>

	  <div class="block c-block block--medium">
	  	<div class="block__header">
  			Динамика выполнения заданий в разрезе сотрудников
	  	</div>
	  	<div class="block__body">
    		<GraphEmployers :organization_id="organization_id" />
	  	</div>
	  </div>

	  <div class="block c-block block--medium">
	  	<div class="block__header">
  			Динамика выполнения заданий по всем объектам
	  	</div>
	  	<div class="block__body" style="padding: 20px;">
    		<div class="c-row">
    			<CompanyAnalyticsBar :organization_id="organization_id" />
    			<CompanyAnalyticsPie :organization_id="organization_id" />
				</div>
	  	</div>
	  </div>


	<!-- <CollapseContainer class="collapse--transparent" :isOpenCreate="true">
	    	<template v-slot:header>
	  			Динамика выполнения заданий в разрезе объектов
	    	</template>
	    	<template v-slot:body>
	    		<GraphHouses :organization_id="organization_id" />
	    	</template>
	    </CollapseContainer> -->
 <!--    <CollapseContainer class="collapse--transparent" :isOpenCreate="true">
    	<template v-slot:header>
  			Динамика выполнения заданий в разрезе сотрудников
    	</template>
    	<template v-slot:body>
				<GraphEmployers :organization_id="organization_id" />
    	</template>
    </CollapseContainer> -->
<!--    	<CollapseContainer class="collapse--transparent" :isOpenCreate="true">
    	<template v-slot:header>
  			Динамика выполнения заданий по всем объектам
    	</template>
    	<template v-slot:body>
  			<div class="c-row">
    			<CompanyAnalyticsBar :organization_id="organization_id" />
    			<CompanyAnalyticsPie :organization_id="organization_id" />
				</div>
    	</template>
    </CollapseContainer> -->

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  // import CollapseContainer from '@/components/ux/CollapseContainer.vue';
  import DatePicker from '@/components/ux/DatePicker.vue';

  import BestIndicator from '@/components/analytics/BestIndicator.vue';
  import WorstIndicator from '@/components/analytics/WorstIndicator.vue';
  import HousesInfo from '@/components/analytics/HousesInfo.vue';
  import GraphHouses from '@/components/analytics/GraphHouses.vue';
  import EmployersInfo from '@/components/analytics/EmployersInfo.vue';
  import GraphEmployers from '@/components/analytics/GraphEmployers.vue';
  import CompanyAnalyticsBar from '@/components/analytics/CompanyAnalyticsBar.vue';
  import CompanyAnalyticsPie from '@/components/analytics/CompanyAnalyticsPie.vue';
</script>

<script>
	export default {

    name: 'PageAnalyticsOrg',

    data: () => ({

    	isLoad: true,
    	breadcrumbs: [],
    	organization: null,

    	topDates: {
    		from: null,
    		to: null,
    	},

    	filter: {
        begin: null,
        end: null,
      },
      displayFilter: {
      	begin: null,
      	end: null,
    	},
    }),

    computed: {
      organization_id () {
        return this.$route.params.organization_id;
      },
      roleId () {
        return this.$store.state.auth.user.roleId;
      },
    },

    created () {

    	this.topDates.from = this.$moment().subtract(7,'days');
    	this.topDates.to = this.$moment();

      Promise.all([this.loadOrganization()])
	      .then(() => {
	        this.beforeRender();
	      })
	      .catch((e)=> {
	        console.log(e);
	      })
	  },

    
    methods: {
      beforeRender () {
      	if(this.roleId == 'director') {
      		// Формируем крошки для директора
	        this.breadcrumbs = [
	          { name: this.$route.meta.name, link: '', ico: this.$route.meta.ico },
	        ];
      	}

      	if(this.roleId == 'admin') {
      		// Формируем крошки для
	        this.breadcrumbs = [
	          { name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico },
	          { name: this.organization.name, link: '' },
	        ];
      	}

      	
      	this.setFilterDefault();
      	this.displayFilter = {...this.filter};

      	this.isLoad = false;

      },

      setFilterDefault () {
      	this.filter.begin = this.$moment(this.organization.dateCreated).format('YYYY-MM-DD');
    		this.filter.end = this.$moment().format('YYYY-MM-DD');
      },

      async loadOrganization () {
        const {data} = await this.axios.get(this.$store.state.api_endpoint + '/companies/' + this.organization_id);
        this.organization = data;
      },

      async reloadComponentsInfo (btn) {
      	this.displayFilter = {...this.filter};
        
        await this.$nextTick();

        btn.classList.add('btn--load');
      	Promise.all([ 
      			this.$refs.housesInfo.loadData(), 
      			this.$refs.employersInfo.loadData()
      		])
	      	.then(() => {})
	      	.catch((e)=> {
	        	console.log(e);
	      	})
	      	.finally(() => {
	      		btn.classList.remove('btn--load');
		  		});
      },

      async clearFilter (e) {
        const btn = e.currentTarget;
        this.setFilterDefault();
        this.reloadComponentsInfo(btn);
      },

      async saveFilter (e) {
        const btn = e.currentTarget;
        this.reloadComponentsInfo(btn);
      },
    },
	}
</script>

<style scoped>
	.collapse {
		margin-bottom: 5px;
	}
	.load-container {
		width: 100%;
		margin-bottom: 10px;
	}
	.collapse__body .load-container, 
	.block .load-container {
		margin-bottom: 0px;
	}
	.c-row {
		display: flex;
		flex-direction: row;
		gap: 10px;
		margin-bottom: 10px;
	}
	.c-row .block {
		width: 50%;
	}
	.c-block {
		margin-bottom: 10px;
	}
	.block__body--row {
		display: flex;
		flex-direction: row;
	}
	.block__left, .block__right {
    padding: 0px;
    width: 50%;
	}
	.block__content {
		padding: 20px;
	}





</style>
