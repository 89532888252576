<template>
	<div class="load-container" :class="{'load' : isLoad}">
		<template v-if="!isLoad && info !== null">
			
			<div class="с">

				<div class="с-header">
					<div class="c-header__left"> 
						<div class="content-header__ico"></div>
						<div class="c-header__name">
							{{ $helpers.buildFio(info.lastName, info.firstName, info.middleName, true) }}
						</div>
					</div>
				</div>

				<div class="block">
					<div class="block__body">

						<grid-table
							:options="{
						        fields: [
						        	{key: 'houseAddress', title: 'Дом'},
						        	{key: 'taskCount', title: 'Всего заданий'},
						        	{key: 'donePercent', title: 'Обработано,&nbsp;%'},
						        	{key: 'expiredPercent', title: 'Просрочено,&nbsp;%'},
						        ],
						        indexField: '№',
					    		defaultSort: {
						    		key: 'expiredPercent',
						    		sort: 'desc',
						    	},
						        items: info.houses,
							}">

						      <template v-slot:default="{ item }">
						        <td> {{ item.houseAddress }} </td>
								<td> {{ item.taskCount }} </td>
								<td> <span class="number number--blue"> {{ item.donePercent }} </span> </td>
								<td> {{ item.expiredPercent }} </td>
						      </template>

						</grid-table>


						<!-- <table class="table-custom">
							<thead>
								<tr>
									<th> Дом </th>
									<th> Всего заданий </th>
									<th> Обработано </th>
									<th> Просрочено </th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(house, index) in info.houses" :key="index">
									<td> {{ house.houseAddress }} </td>
									<td> {{ house.taskCount }} </td>
									<td> {{ house.donePercent }} </td>
									<td> {{ house.expiredPercent }} </td>
								</tr>
							</tbody>
						</table> -->
					</div>
				</div>

			</div>

		</template>
	</div>
</template>

<script setup>
  import GridTable from '@/components/ux/FastGridTable.vue';
</script>

<script>

  	export default {

    	props: ['employee_id', 'begin', 'end'],

    	data: () => ({

      		isLoad: true,

      		info: null,
    	}),

    	created () {
	      	Promise.all([ this.loadInfo() ])
		      	.then(() => {
		      	})
		      	.catch(()=> {
		      	})
		      	.finally(() => {
		        	this.isLoad = false;
		      	})
	    },

	    methods: {
	    	beforeRender () {
		   
	    	},
	    	async loadInfo () {

	    		const param = new URLSearchParams({});

        	param.set("employee_id", this.employee_id);
        	param.set("begin", this.begin);
		      param.set("end", this.end);

        	const {data} = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/analytic-employee?' + param);

          this.info = data;
	    	},
	    },


	}
	

</script>

<style scoped>

	.с-header {
	    background-color: var(--cozh-white);
	    padding: 15px 25px;
	    border-radius: 8px;
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: space-between;
	    margin-bottom: 10px;
	}

	.c-header__ico {

	}

	.c-header__name {
		font-size: 22px;
    	font-weight: 600;
	}

	.c-header__left {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;
	}

	.content-header__ico {
		height: 50px;
    	width: 50px;
    	background-color: rgba(var(--cozh-blue-code), 1);
    	border-radius: 50%;
    	display: flex;
    	align-items: center;
    	justify-content: center;
	}


</style>