<template>

  <ConfirmDialogue ref="confirmDialogue" />
  <MessageDialogue ref="messageDialogue" />

	<div class="load-container" :class="{'load' : isLoad}">
  	<div class="content" v-if="!isLoad && !isError">

  		<div class="component-container">
  			<div class="component-container__left">

  				<div class="component-work" :class="'component-work--' + task.status">
		  			<div class="component-work__header">
		  				<div class="component-work__badge">
		  					<IconWork v-if="task.actionType == 'work'" :color="statusColor" />
								<IconReview v-if="task.actionType == 'review'" :color="statusColor" />
								<IconControl v-if="task.actionType == 'control'" :color="statusColor" />
		  				</div>
		  				<div class="component-work__num"> {{ task.num }} </div>
		  				<div class="component-work__status" :style="{'color': statusColor}"> 
		  					<span :style="{'background-color': statusColor}"></span>
		  					{{ statusText }} 
		  				</div>
		  			</div>
		  			<div class="component-work__body">
								{{ task.name }}
		  			</div>
		  		</div>
		  		<div class="component-comment" v-if="task.supplierComment">
						<div class="component-comment__label">
							Комментарий постановщика
						</div>
						<div class="component-comment__text">
							{{ task.supplierComment }}
						</div>
					</div>
					<div class="component-comment" v-if="task.employeeComment">
						<div class="component-comment__label">
							Комментарий исполнителя
						</div>
						<div class="component-comment__text">
							{{ task.employeeComment }}
						</div>
					</div>
					<div class="component-comment" v-if="task.imgLinks && task.imgLinks.length > 0">
						<div class="component-comment__label">
							Фотографии
						</div>
						<GalleryPhoto :images="task.imgLinks" />
					</div>

					<!-- Задача на основе текущей -->

					<!-- Пока нет задачи стрелочка + кнопка создать -->
					<!-- Если есть задача - сразу форма с редактированием -->

					<template v-if="task.status == 'issue' || task.status == 'failed'">
						<template v-if="template !== null">

							<div class="separator"><img src="@/assets/img/arrow-down-grey.svg"></div>

							<template v-if="template.id == null">
								<template v-if="isShowForm">
									<div class="component-new">
										<div class="component-new__header">
											Создать задачу на основе текущей
										</div>
										<div class="component-new__body">
											<div class="form-group">
												<DatePicker v-model="form.date" label="Дата назначения" defaultTop="true"/>
											</div>
											<div class="form-group">
												<SelectPicker :list="functions" label="Квалификация" v-model="form.functionId" defaultTop="true" isClear="true" />
											</div>
											<div class="form-group">
												<SelectPicker :list="filteredEmployees" label="Исполнитель" v-model="form.employeeId" defaultTop="true" isClear="true" />
											</div>
											<div class="form-group">
												<div class="form-control">
													<textarea v-model="form.employeeComment" rows="2"></textarea>
													<label> Комментарий </label>
												</div>
											</div>
											<div class="btn-group">
												<a class="btn btn--lg btn--default btn--inline" :class="{'disabled': isDisabledSaveButton}" @click="saveTask($event)">
													<div class="btn--text"> Сохранить </div>
													<div class="btn--loader"></div>
												</a>
											</div>
										</div>
									</div>
								</template>
								<template v-else>
									<a class="btn btn--lg btn--default" style="width: 100%;" @click="isShowForm = true">
										<div class="btn--text"> Создать задачу на основе текущей </div>
										<div class="btn--loader"></div>
									</a>
								</template>
							</template>
							<template v-else>
					  		<div class="component-info">
					  			<div class="component-info__header">
					  				Задача на основе текущей
					  			</div>
					  			<div class="component-info__body">
					  				<div class="component-info__item">
					  					<div class="component-info__badge">
					  						<img src="@/assets/img/task-icons/1.svg">
					  					</div>
					  					<div class="component-info__content">
					  						<div class="component-info__label">
					  							Дата назначения
					  						</div>
					  						<div class="component-info__data">
					  							<span> {{ $moment(template.dateCreated).format('DD.MM.YYYY') }} </span>
					  						</div>
					  					</div>
					  				</div>
					  				<div class="component-info__item">
					  					<div class="component-info__badge">
					  						<img src="@/assets/img/task-icons/3.svg">
					  					</div>
					  					<div class="component-info__content">
					  						<div class="component-info__label">
					  							Квалификация
					  						</div>
					  						<div class="component-info__data">
					  							{{ functionNameById(template.functionId) }}
					  						</div>
					  					</div>
					  				</div>
					  				<div class="component-info__item">
					  					<div class="component-info__badge">
		  									<img src="@/assets/img/task-icons/2.svg">
					  					</div>
					  					<div class="component-info__content">
					  						<div class="component-info__label">
					  							Исполнитель
					  						</div>
					  						<div class="component-info__data">
					  							{{ employeeNameById(template.employeeId) }}
					  						</div>
					  					</div>
					  				</div>
					  				<div class="component-info__item">
					  					<div class="component-info__badge">
		  									<!-- <img src="@/assets/img/task-icons/2.svg"> -->
					  					</div>
					  					<div class="component-info__content">
					  						<div class="component-info__label">
					  							Комментарий
					  						</div>
					  						<div class="component-info__data">
					  							{{ template.supplierComment }}
					  						</div>
					  					</div>
					  				</div>
					  			</div>
					  		</div>
							</template>
						</template>
					</template>

  			</div> 


  			<div class="component-container__right">
		  		<div class="component-info">
		  			<div class="component-info__header">
		  				<span> Назначено </span>
		  				<span> {{ $moment(task.dateCreated).format('DD.MM.YYYY') }} </span>
		  			</div>
		  			<div class="component-info__body">
		  				<div class="component-info__item">
		  					<div class="component-info__badge">
		  						<img src="@/assets/img/task-icons/1.svg">
		  					</div>
		  					<div class="component-info__content">
		  						<div class="component-info__label">
		  							Крайний срок
		  						</div>
		  						<div class="component-info__data">
		  							<span v-if="task.dateExpire !== null ">
		  								{{ $moment(task.dateExpire).format('DD.MM.YYYY') }}
		  							</span>
		  							<span v-else>
		  									-
		  							</span>
		  						</div>
		  					</div>
		  				</div>
		  				<div class="component-info__item">
		  					<div class="component-info__badge">
		  						<img src="@/assets/img/task-icons/4.svg">
		  					</div>
		  					<div class="component-info__content">
		  						<div class="component-info__label">
		  							Место
		  						</div>
		  						<div class="component-info__data">
		  							<div class="component-info__path" v-if="task.title !== ''">
											<div class="component-info__path-item" v-for="(path, index) in currentPath" :key="index">
												{{ path }}
											</div>
										</div>
										<span v-else> - </span>
		  						</div>
		  					</div>
		  				</div>
		  				<div class="component-info__item">
		  					<div class="component-info__badge">
		  						<img src="@/assets/img/task-icons/3.svg">
		  					</div>
		  					<div class="component-info__content">
		  						<div class="component-info__label">
		  							Квалификация
		  						</div>
		  						<div class="component-info__data">
		  							{{ task.function.name }}
		  						</div>
		  					</div>
		  				</div>
		  				<div class="component-info__item">
		  					<div class="component-info__badge">
		  						<img src="@/assets/img/task-icons/2.svg">
		  					</div>
		  					<div class="component-info__content">
		  						<div class="component-info__label">
		  							Сотрудник
		  						</div>
		  						<div class="component-info__data">
		  							{{ task.empl.name }}
		  						</div>
		  					</div>
		  				</div>
		  			</div>
					</div>

					

  		  </div>
  		</div>

  	</div>
	</div>

</template>

<script setup>
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';

  import GalleryPhoto from '@/components/ux/GalleryPhoto.vue';
  import SelectPicker from '@/components/ux/SelectPicker.vue';
	import DatePicker from '@/components/ux/DatePicker.vue';

  import IconControl from '@/components/icons/task-types/IconControl.vue';
  import IconWork from '@/components/icons/task-types/IconWork.vue';
  import IconReview from '@/components/icons/task-types/IconReview.vue';
</script>

<script>

  export default {

    name: 'PageTrackerHouseTask',

    props: ['house_id', 'task_id', 'organization_id'],

    data: () => ({

      isLoad: true,
      isError: false,
      isShowForm: false,

      task: null,
		  template: null,

      form: {
	    	date: null,
	    	functionId: null,
	    	employeeId: null,
	    	employeeComment: null,
	    },

      employees: [],

    }),

    watch: {
    	// Устанавливаем сотрудника в зависимости от отфильтрованного списка
    	'form.functionId': function () {
        const idx = this.filteredEmployees.findIndex( (employee) => { return employee.id == this.form.employeeId });
        this.form.employeeId = (idx !== -1) ? this.form.employeeId : null;
    	} 
    },

    computed: {
    	statusText () {
    		switch (this.task.status) {
    			case 'assigned':
    				return 'Назначено';
    			case 'done':
    				return 'Всё в порядке';
    			case 'issue':
    				return 'Есть проблемы';
    			case 'failed':
    				return 'Не сделано';
    			case 'expired':
    				return 'Просрочено';
    			default:
    				return 'Назначено';
    		}
    	},
    	statusColor () {
    		switch (this.task.status) {
    			case 'assigned':
    				return 'var(--cozh-grey)';
    			case 'deferred':
    				return 'var(--cozh-grey)';
    			case 'done':
    				return 'var(--cozh-blue)';
    			case 'issue':
    				return 'var(--cozh-orange)';
    			case 'failed':
    				return 'var(--cozh-red)';
    			case 'expired':
    				return 'var(--cozh-dark-red)';
    			default:
    				return 'var(--cozh-grey)';
    		}
    	},
    	currentPath () {
    		return this.task.title.split(' / ');
    	},
    	functions () {
        return this.$store.state.functions;
      },
      
      functionNameById () {
      	return (id) => {
	      	const idx = this.functions.findIndex( (item) => { return item.id == id } );
	      	return this.functions[idx].name;
      	}
      },

      employeeNameById () {
      	return (id) => {
	      	const idx = this.employees.findIndex( (item) => { return item.id == id } );
	      	return this.employees[idx].name;
      	}
      },

      filteredEmployees () {
    		let list = [];
	        if(this.form.functionId !== null) {
	          	this.employees.forEach((employee) => {
	          		const idx = employee.functions.findIndex((func) => { return func.id === this.form.functionId });
	          		if(idx !== -1) { list.push(employee); }
	          	});
	        } else {
	          list = this.employees;
	        }
	        return list;
    	},
    	isDisabledSaveButton () {
    		const data = [];
      	for (let key in this.form) {
      		data.push(this.form[key]);
      	}
      	return data.includes(null) ? true : false;
    	},

    },

    created () {
    	Promise.all([ 
  			this.loadTask(), 
  		])
    	.then(() => {
      	this.beforeRender();
      	this.isLoad = false;
    	})
    	.catch((e)=> {
      	console.log(e);
      	this.isError = true;
      	this.isLoad = false;
    	})
    },

    methods: {
    	beforeRender () {
	      if(this.task.status == 'issue' || this.task.status == 'failed') {
	      	Promise.all([ 
						this.loadEmployees(), 
	      		this.$store.dispatch('getFunctions'),
			      this.loadChildTemplate()
	      	])
	      	.then(() => {

	      	})
	      	.catch(()=> {
	      	})
	      }
    	},

    	async loadTask () {
        const {data} = await this.axios.get(this.$store.state.api_endpoint + '/tracker/' + this.house_id + '/tasks/' + this.task_id);
        this.task = data;
    	},
    	async loadEmployees () {
  	  	const { data } = await this.axios.get(this.$store.state.api_endpoint + '/employees/?companyId=' + this.organization_id + '&detailed=true');
        this.employees = data.data;
  	  },
			async loadChildTemplate () {
				const { data } = await this.axios.get(this.$store.state.api_endpoint + '/checklist/' + this.task.actionType + '/' + this.task.empl.id + '/' + this.task.id + '/childTemplate');
        
        this.template = data;

        if(this.template.dateCreated && this.template.dateCreated !== null) {
        	this.form.date = this.$moment(this.template.dateCreated).format("YYYY-MM-DD");
        } else {
        	this.form.date = this.$moment().add(1, 'days').format("YYYY-MM-DD");
        }

        // Сотрудник
        this.form.employeeId = this.template.employeeId;
        // Квалификация - скинет или оставит сотрудника watcher
        this.form.functionId = this.template.functionId;
        // Комментарий
        this.form.employeeComment = this.template.supplierComment;

			},


  	  // Сохранить задачу
			async saveTask (e) {
				
				const btn = e.currentTarget;
				btn.classList.add('btn--load');

				const params = {
					ttl: this.template.ttl,
					employeeId: this.form.employeeId,
					functionId: this.form.functionId,
					employeeComment: this.form.employeeComment,
					dateCreated: this.$moment(this.form.date).format('YYYY-MM-DDT00:00:00+00:00'),
				};

				try {
					
					// Создаём дочернюю задачу
					const {data} = await this.axios.post(this.$store.state.api_endpoint + '/checklist/' +  this.task.actionType + '/' + this.task.empl.id + '/' + this.task.id + '/childTemplate', params);
					
					// Обновляем шаблон (уже есть ID)
        	this.template = data;

        	// Задача было локальной, вызываем настоящее создание
					await this.axios.post(this.$store.state.api_endpoint + '/checklist/create/childTemplate', {ids: [ this.task.id ]});

					// Сигнализируем
					this.$refs.messageDialogue.show({
          	message: 'Задача поставлена',
          	okButton: 'Продолжить',
          	cancelButton: false,
        	});

				} catch (e) {
					this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
				}
				btn.classList.remove('btn--load');
			},


    },
  }
</script>


<style scoped>

	.separator {
		width: 100%;
		height: 50px;
		background-position: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}



	.component-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.component-container__left {
		width: 100%;
		margin-right: 15px;
	}

	.component-container__right {
		width: 525px;
		min-width: 525px;
	}



	.component-new {
		background-color: var(--cozh-white);
		border-radius: 15px;
		width: 100%;
	}
	.component-new__header {
		border-bottom: 1px solid var(--cozh-light-grey);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		height: 65px;
		padding: 0px 25px;
		    font-size: 18px;
    font-weight: 500;
    line-height: 1.1;
	}
	.component-new__body {
		padding: 20px 20px;
	}






	.component-info {
		background-color: var(--cozh-white);
		border-radius: 15px;
		overflow: hidden;
		width: 100%;
	}
	
	.component-info__header {
		border-bottom: 1px solid var(--cozh-light-grey);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		height: 65px;
		padding: 0px 25px;
		font-size: 18px;
    font-weight: 500;
	}

	.component-info__header span:first-child {
 		margin-right: 10px;
		color: var(--cozh-grey);
 		font-size: 15px;
	 	line-height: 1.1;
	 	word-wrap: break-word;
	}

	.component-info__header span:last-child {
		font-size: 15px;
    font-weight: 500;
    line-height: 1.1;
    word-wrap: break-word;
	}
	.component-info__body {
		padding: 8px 20px;
	}

	.component-info__item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.component-info__item:not(:last-child) {
		border-bottom: 1px solid var(--cozh-light-grey);
	}


	.component-info__badge {
		height: 40px;
		width: 40px;
		min-height: 40px;
    min-width: 40px;
		background-color: rgba(var(--cozh-blue-code), .12);
		border-radius: 50%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-right: 15px;
	}

	.component-info__content {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.component-info__label {
		color: var(--cozh-grey);
 		font-size: 10px;
 		font-weight: 500;
 		text-transform: uppercase;
 		letter-spacing: 0.50px;
 		word-wrap: break-word
	}

	.component-info__data {
		font-size: 15px;
		font-weight: 500;
		line-height: 1.2;
 		word-wrap: break-word;
	}

	.component-info__path {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	.component-info__path-item {
 		font-size: 15px;
	 	font-weight: 500;
	 	max-width: 50%;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
	 	white-space: nowrap;
	 	position: relative;
	}
	.component-info__path-item:not(:last-child) {
		padding-right: 32px;
	 	max-width: calc(50% + 20px);
	}
	.component-info__path-item:not(:last-child)::before {
		content: url('../../assets/img/check-right-grey.svg');
		position: absolute;
		right: 13px;
		top: 50%;
		transform: translateY(-50%);
	}









	.component-comment {
		background-color: var(--cozh-white);
		border-radius: 15px;
		padding: 20px 20px;
		margin-top: 15px;
	}
	.component-comment__label {
		color: var(--cozh-grey);
	 	font-size: 10px;
	 	font-weight: 500;
	 	text-transform: uppercase;
	 	letter-spacing: 0.50px;
	 	margin-bottom: 10px;
	}
	.component-comment__text {
 		font-size: 14px;
 		word-wrap: break-word;
	}



	.component-work {
		background-color: var(--cozh-white);
		border-radius: 15px;
		min-height: 340px;
	}
	
	.component-work__header {
		border-bottom: 1px solid var(--cozh-light-grey);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		height: 65px;
		padding: 0px 13px;
	}

	.component-work__body {
		padding: 20px 20px;
		font-size: 20px;
		line-height: 1.4;
		font-weight: 500;

	}

	.component-work__badge {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		margin-right: 12px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: rgba(233, 241, 244, 1);
	}

	.component-work__num {
	 	font-size: 15px;
	 	font-weight: 500;
	 	line-height: 1.1;
	 	word-wrap: break-word;
		margin-right: 20px;
	}

	.component-work__status {
	 	font-size: 15px;
	 	line-height: 1.1;
	 	word-wrap: break-word;
	 	padding-left: 15px;
	 	position: relative;
	 	font-weight: 500;
	}

	.component-work__status > span {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
	}


	.component-work--assigned .component-work__badge {
		background-color: rgba(233, 241, 244, 1);
	}
	.component-work--done .component-work__badge {
		background-color: rgba(233, 241, 244, 1);
	}
	.component-work--issue .component-work__badge {
		background-color: rgba(var(--cozh-orange-code), .12);
	}
	.component-work--failed .component-work__badge {
		background-color: rgba(var(--cozh-red-code), .12);
	}
	.component-work--expired .component-work__badge {
		background-color: rgba(var(--cozh-dark-red-code), .08);
	}

</style>